import React from "react";
import { useState, useEffect } from "react";
import {DefaultPlayer as Video} from "react-html5video"
import "react-html5video/dist/styles.css"
import thum from "../../images/vid2.png"
import eventBus from "../../js/Event-bus";

const TopicVideo = () => {
    const [videoURL, setVideoURL] = useState('')
    useEffect(() => {
        eventBus.on("send-selected-topic-info", (data) =>{
        //   this.setState({ message: data.message })
            console.log("inside course videos : ", data.message)
            setVideoURL(data.message.s3PresignedUrlVideo)
        });
    })
    // componentWillUnmount() {
    //     eventBus.remove("couponApply");
    //   }
    return (
        <div className="col-12 p-0 background-black radius10">
            {/* <Video loop poster={thum}>
            <source src={videoURL}></source></Video> */}
            <video src={videoURL} controls className="videotag radius10"></video>
        </div>
    )
}
export default TopicVideo;