import React, { useEffect } from "react";
import Navb from "../components/Navb";
import "../styles/Student.css";
import Sidebar from "../components/Sidebar";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Dropdown from "react-bootstrap/Dropdown";

function Updateuser() {

  // UseStates to select, set and crop-image

  const [src, selectFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 200,
    height: 200,
  });

  // UseState to set resulting image

  const [result, setResult] = useState(null);

  // UseState for the crop functionality popup

  const [pop2, setPop2] = useState(false);

  function removeProfile() {
    setResult("");
    console.log(result);
  }

  // Function to select the uploaded image by the user

  const handleFileChange = (e) => {
    selectFile(URL.createObjectURL(e.target.files[0]));
    setPop2(true);
  };

  // Function to crop the selected image

  function imagecrop() {
    const canvas = document.createElement("canvas");
    const scalex = image.target.naturalWidth / image.target.width;
    const scaley = image.target.naturalHeight / image.target.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image.target,
      crop.x * scalex,
      crop.y * scaley,
      crop.width * scalex,
      crop.height * scaley,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
    setPop2(false);
  }

  //Renders the result(picture/placeholder) on screen load according to the condition in useEffect

  useEffect(() => {
    if (result) {
      uploadedImage.current.src = result;
    } else {
      uploadedImage.current.src = require("../images/ProfilePlaceholder.png");
    }
  }, [result]);

  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);


  //Below useState and the function runs the change password popup

  const [pop, setPop] = useState(false);

  function formPop(e) {
    e.preventDefault();
    setPop((pValue) => !pValue);
  }

  return (
    <>
      <div>
        <Sidebar />

        {/* Main Container */}

        <div className="dashboard-container mx-4">
          <Navb result={result} />

          <div className="mb-2"></div>

          {/* Conditional rendering for crop functionality popup */}

          {pop2 && (
            <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
              <div className=" d-flex flex-column">
                {src && (
                  <>
                    <ReactCrop
                      crop={crop}
                      aspect={1 / 1}
                      onChange={(c) => setCrop(c)}
                    >
                      <img
                        style={{ width: "40vw" }}
                        src={src}
                        onLoad={setImage}
                      />
                    </ReactCrop>
                    <button onClick={imagecrop} className="update-picture py-2">
                      Update Picture
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          {/* Conditional rendering for change password popup */}

          {pop && (
            <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
              <div className=" d-flex flex-column P-Update-Success-2  rounded-2 ">
                <div className="add-prog-pop mb-3 rounded-top">
                  <h4 style={{ fontWeight: "300" }} className="my-3">
                    Change Password
                  </h4>
                  <img
                    onClick={formPop}
                    src={require("../images/cancel.png")}
                    alt="Image not found"
                    className="cancelimg my-2 me-3"
                  />
                </div>

                <Form className=" d-flex flex-column align-items-center">
                  <Form.Group
                    style={{ width: "90%" }}
                    className=""
                    controlId="formBasicPassword"
                  >
                    <Form.Label style={{ fontWeight: "350" }}>
                      Old Password*
                    </Form.Label>
                    <Form.Control
                      className="mb-3"
                      style={{ height: "40px" }}
                      type="password"
                    />
                    <Form.Label style={{ fontWeight: "350" }}>
                      New Password*
                    </Form.Label>
                    <Form.Control
                      className="mb-3"
                      style={{ height: "40px" }}
                      type="password"
                    />
                    <Form.Label style={{ fontWeight: "350" }}>
                      Re - type New Password*
                    </Form.Label>
                    <Form.Control
                      className="mb-3"
                      style={{ height: "40px" }}
                      type="password"
                    />
                  </Form.Group>

                  <button className="my-4 atb-btn ">Update Password</button>
                </Form>
              </div>
            </div>
          )}



          <div className=" bgwhite shadow py-4 my-5 px-5">
            <div className="mt-4 mb-5">

              {/* File(image) uploading input */}

              <input
                type="file"
                accept="image/*"
                ref={imageUploader}
                style={{
                  display: "none",
                }}
                onChange={handleFileChange}
              />
              <div className="edit-container d-inline-block position-relative">
                <img
                  ref={uploadedImage}
                  style={{
                    width: "118px",
                    height: "118px",
                    borderRadius: "50%",
                  }}
                />

                <Dropdown
                  className=" d-inline-block position-absolute edit-dropdown"
                  drop={"end"}
                >
                  <Dropdown.Toggle
                    className="dropdown-more shadow"
                    variant="none"
                  >
                    <img
                      src={require("../images/edit.png")}
                      alt="Image not found"
                      className="more"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => imageUploader.current.click()}
                      eventKey="1"
                    >
                      Update Picture
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removeProfile} eventKey="2">
                      Remove Picture
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <Form>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Roll No*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter roll no."
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>First Name*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter first name"
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Last Name*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter last name"
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>Email*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="e.g. xxxx@gmail.com"
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Phone Number*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter mobile"
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>College Name*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter college name"
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
                  <h6>Location*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Enter address"
                  />
                </Col>
                <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                  <h6>Year of Graduation*</h6>
                  <Form.Control
                    className="admin-profile-update-fields"
                    placeholder="Year of grad."
                  />
                </Col>
              </Row>
            </Form>

            <div className="row">
              <div className="col-12 col-lg-5">
                <button className="profile-buttons-update mt-3 mb-lg-4 mb-4">
                  Save
                </button>
              </div>
              <div className="col-12 col-lg-5">
                <h6
                  onClick={formPop}
                  className=" mt-lg-4 pt-lg-2 mb-4 change-pass"
                >
                  Change Password
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Updateuser;
