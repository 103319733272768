import React from "react";
import "../../styles/App.css";
import "../../styles/Dashboard.css";
import "../../fonts/Roboto/Roboto-Black.ttf";
import Accordion from "react-bootstrap/Accordion";
import { useState, useEffect } from "react";
// import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from 'react-bootstrap/Spinner';
// event bus
import eventBus from "../../js/Event-bus";
const CourseTopicsList = ({courseData, setCourseData}) => {

  const [loading, setLoading] = useState(false)
  const [selectedTopicData, setSelectedTopicData] = useState({})


  
  function selectTopicToView(e, topic, i){
    console.log("selected topic to view : ", topic , i)
    setSelectedTopicData(topic)
    eventBus.dispatch("send-selected-topic-info", { message: topic });
  }
  
  return (
    <>
              <div className="bgwhite shadow fit-content-h radius10 pb-5">

                <h4 className="pt-4 pb-2 ps-3 font18" style={{ fontWeight: "350" }}>{/* Course Completion */} Course Details</h4>
                  {/* <div className="d-flex mt-2 ps-3 mb-4">
                    <ProgressBar className="progress-bar-0 rounded-3 me-2" now={100}/>
                    <ProgressBar className="progress-bar-0 rounded-3 me-2" now={100}/>
                    <ProgressBar className="progress-bar-0 rounded-3 me-2" now={0}/>
                    <ProgressBar className="progress-bar-0 rounded-3 me-2" now={0}/>
                    <ProgressBar className="progress-bar-0 rounded-3 me-2" now={0}/>
                    <ProgressBar className="progress-bar-0 rounded-3 me-2" now={0}/>
                    <ProgressBar className="progress-bar-0 rounded-3" now={0} />
                  </div> */}

                <Accordion className="">
                  {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-3"> <Spinner animation="border" variant="secondary" /></div> :
                  courseData.moduleMasterList.map((res,index)=>{
                    return(
                      <Accordion.Item className="programdetails-accordian background-aliceblue radius10 mb-2 col-11 mx-auto" key={'item'+index} eventKey={index}>
                        <Accordion.Header className="p-1 background-aliceblue radius10">
                          <div className={(selectedTopicData && selectedTopicData.moduleId===res.lmsModuleId)?"d-flex blue":"d-flex"}>
                            <span className="me-4 ms-2 my-auto moduleCount d-flex justify-content-center align-items-center">{index+1}</span>
                            <span className="">{res.lmsModuleName}</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {res.topicMasterList.map((topic,i)=>{
                            return(
                              <div className="col-12" key={'topic'+i}>
                              <div className={(selectedTopicData && selectedTopicData.lmsTopicMasterId===topic.lmsTopicMasterId)?"d-flex mb-2 pointer blue":"d-flex mb-2 pointer"}>
                                <span className="me-4 ms-2 my-auto submoduleCount d-flex justify-content-center align-items-center">{index+1}.{i+1}</span> 
                                <span className=""  onClick={(e) => selectTopicToView(e, topic, i)}>{topic.topicName}</span>
                              </div>
                              </div>
                            )
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }) 
                  }
                </Accordion>
              </div>
    </>
  );
};

export default CourseTopicsList;
