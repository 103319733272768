import React, {useState} from 'react'
import Input from "../components/Input";
import Button from "../components/Button";
import "../styles/Forgetpass.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_signup';

const Updatepass = () => {

  const [error, setError] =  useState()
  const [pop, setPop] = useState(false);
  const [pass, setPass] = useState('');
  const [confirm, setConfirm] = useState('');
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(false)
  const location = useLocation();

  useEffect(() => {
    if(confirm.length>0  && pass===confirm) {
      setDisable(false)
    }
    else {
      setDisable(true)
    }
  }, [confirm])

  async function changePass(e) {

    e.preventDefault();
    setLoading(true)
    // await axios({
    //   method: 'put',
    //   url: 'http://localhost:12000/userloginLMS/updatePwdForgot',
    //   headers: {}, 
    //   data: {
    //     userEmail: localStorage.getItem("userEmail"),
    //     passwordSalt: pass
    //   }
    // });
    httpsignup.put("/userloginLMS/updatePwdForgot", {
      userEmail: localStorage.getItem("userEmail"),
      passwordSalt: pass
    }).then((response) => {
      console.log("change password : ", response)
      if (response.data==='*Password should contain Minimum 8 to Maximum 16 Characters and 1 Special Character, 1 UpperCase  Letter, 1 Number') {
        setError(response.data);
      }
      else{
        setError()
        setPop(true)
        setTimeout(() => {
          navigate("/");
        }, 1600);

      }
      setLoading(false)
    }).catch((err)=>{
      console.log(err)
    })
  }

  function passChange(e){
    setPass(e.target.value)
  }

  function confirmChange(e){
    setConfirm(e.target.value)
  }

  const navigate = useNavigate();

 

  // function formPop(e) {
  //   e.preventDefault();
  //   setPop(pValue => !pValue);
  //   setTimeout(() => {
  //     navigate("/");
  //   }, 1600);
  // }


  return (
    <>

    

   {pop && <div  className='position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center'>
<div className=' d-flex flex-column justify-content-center align-items-center P-Update-Success-2 '>
      <div>
      <img
                src={require("../images/Tick.png")}
                alt="Image not found"
                className="img-fluid"
              />
      </div>
      <p className='text-center w213 '>Sucessfully your
password has been updated</p>
</div>
    </div>}








    
    <div className='container d-flex justify-content-center align-items-center login-back-container'>

<div className=' bgwhite m-5 rounded-1 d-flex shadow-lg'>

    <div className='signin-image'>

    </div>

    <div className='px-5 d-flex flex-column login-details-container align-items-center'>

    <div className="logo">
    <img src={require("../images/lock.png")} alt="Image not found" />
  </div>

  <p className="forgetpass-heading">Forget Password</p>

  <p style={{
              color:"#A8A8A8",
              fontSize: "12px",
              marginRight: "15px",
              marginTop: "10px",
              marginBottom: '31px'
            }}
            >Your new password must be different from previous
            used password</p>
            <form onSubmit={changePass}>
            <div>
            <p className="e-heading">New Password</p>
            <Input change={passChange} type="password" name="Npass" placeholder="" />
          </div>
          <div>
            <p className="e-heading">Confirm Password</p>
            <Input change={confirmChange} type="password" name="Cpass" placeholder="" />
            {loading ? <div className='d-flex justify-content-center'><Spinner animation="border" variant="warning" /></div>  : error && <div className="errMsg">{error}</div>}
          </div>
          <Button disable={disable} class='update-pass-btn' button="Update" />
          </form>


          {/* {error && <div>{error}</div>} */}

  <p style={{color: "#010101",
      opacity: 0.52,fontSize: "10px"}}>@2022 all rights reserved</p>


        </div>


</div>

</div>
    





 {/* <div className="flex-container">
      <div className="forgetpass-container">
        <div className="forgetpass-image">
          <div className="color-fill"></div>
        </div>
        <form onSubmit={formPop} className="forgetpass-content">
          <div className="logo">
            <img src={require("../images/lock.png")} alt="Image not found" />
          </div>
          <p className="forgetpass-heading">Forget Password</p>
          <p style={{
              color:"#A8A8A8",
              fontSize: "12px",
              fontFamily: "Roboto",
              marginTop: "13px",
              width: '283px',
              marginBottom: '31px'
            }}
            >Your new password must be different from previous
            used password</p>
          <div>
            <p className="e-heading">New Password</p>
            <Input type="password" name="Npass" placeholder="" />
          </div>
          <div>
            <p className="e-heading">Confirm Password</p>
            <Input type="password" name="Cpass" placeholder="" />
          </div>
          <Button in class='forgetpass-btn py-2 mtop14' button="Update" />
          <p
            style={{
              color: "#010101",
              opacity: 0.46,
              fontSize: "8px",
              fontFamily: "Roboto",
              marginTop: "142px",
            }}
          >
            @2022 all rights reserved
          </p>
        </form>
      </div>
    </div>  */}
       
    </>
  )
}

export default Updatepass;
