import React from "react";
import Sidebar from "../components/Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import "../styles/Dashboard.css";
import "../styles/Student.css";
import Navb from "../components/Navb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useState, useEffect } from "react";
import "react-html5video/dist/styles.css";
import httpuserdetails from '../js/http-lms-userdetails';
import httpvideos from "../js/http-lms_videos"
import { useNavigate} from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

function S_dashboard() {

  const navigate = useNavigate()

  
  //Below useState and handleClick function is used to render the full course details

  const [userDetails, setUserDetails] = useState([])

  const [programDetails, setProgramDetails] = useState([])

  const [loading, setLoading] = useState(true)

  function handleClick(e) {
    navigate("/course-details/"+programDetails[0].programId)
  }


  useEffect(()=>{
    getLoggedInUserDetails()
  }, [])




  const getLoggedInUserDetails =async ()=>{
    httpuserdetails.get(`/userprofileLMS/getLoggedInUserDetails?userId=${sessionStorage.getItem("userId")}`)
    .then((response) => {
      console.log("UserDetails:", response)
      sessionStorage.setItem("profileid", response.data.profileId)
      sessionStorage.setItem("roleid", response.data.roleId)
      programCards();
    setUserDetails(response.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  

  const programCards =async ()=>{
    setLoading(true)
    httpvideos.get('/lmsVideoRecordingManager//api/v1/getLMSProgramDetails?roleId='+sessionStorage.getItem("roleid")+'&profileId='+sessionStorage.getItem("profileid"))
    .then((response) => {
      console.log("progDetails:", response)
    setProgramDetails(response.data)
    setLoading(false)
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <div>
      {/* Calling Sidebar component */}

      <Sidebar />

      {/* Main Container */}

      <div className="dashboard-container mx-4 right-container">
        <Navb />

        
          <div onClick={handleClick} className="yourCourses-main-container px-md-5">

          <h1 className="mt-4 mb-4">Your Programs</h1>

          {loading ?   <div className="yourCourses d-flex justify-content-center bgwhite shadow mt-4 py-5 rounded-2"> <Spinner animation="border" variant="secondary" /></div> :

            <div onClick={handleClick}
              className="yourCourses d-flex flex-column bgwhite shadow mt-4  rounded-2"
            >
              <div onClick={handleClick} className="d-flex align-items-center pt-4 ps-4 yourCourses-line-1">
                <h1 onClick={handleClick} className="">{programDetails[0].programName}</h1>
                <div onClick={handleClick} className="mb-2 ms-5">
                  <img onClick={handleClick}
                    src={require("../images/star.png")}
                    alt="Image not found"
                    className="me-1"
                  />
                  <img onClick={handleClick}
                    src={require("../images/star.png")}
                    alt="Image not found"
                    className="me-1"
                  />
                  <img onClick={handleClick}
                    src={require("../images/star.png")}
                    alt="Image not found"
                    className="me-1"
                  />
                  <img onClick={handleClick}
                    src={require("../images/star.png")}
                    alt="Image not found"
                    className="me-1"
                  />
                  <img onClick={handleClick}
                    src={require("../images/star.png")}
                    alt="Image not found"
                    className=""
                  />
                </div>
                <h1 onClick={handleClick} className="ms-auto pe-5">2/7</h1>
              </div>
              <div onClick={handleClick} className="d-flex  mt-2 px-5 mb-1">
                <ProgressBar onClick={handleClick}
                  className="progress-bar-0 rounded-3 me-2"
                  now={100}
                />
                <ProgressBar onClick={handleClick}
                  className="progress-bar-0 rounded-3 me-2"
                  now={100}
                />
                <ProgressBar onClick={handleClick}
                  className="progress-bar-0 rounded-3 me-2"
                  now={0}
                />
                <ProgressBar onClick={handleClick}
                  className="progress-bar-0 rounded-3 me-2"
                  now={0}
                />
                <ProgressBar onClick={handleClick}
                  className="progress-bar-0 rounded-3 me-2"
                  now={0}
                />
                <ProgressBar onClick={handleClick}
                  className="progress-bar-0 rounded-3 me-2"
                  now={0}
                />
                <ProgressBar onClick={handleClick} className="progress-bar-0 rounded-3" now={0} />
              </div>

              <div onClick={handleClick} className="d-flex ps-3 ps-md-5 mt-2 mb-3 mx-3">
                <h6 onClick={handleClick}>Lessons {programDetails[0].modulesCount}</h6>
                <h6 onClick={handleClick} className="mx-5">Assignments {programDetails[0].assignmentCount}</h6>

                <div onClick={handleClick} className="d-flex">
                  <img onClick={handleClick}
                    src={require("../images/assignments.png")}
                    alt="Image not found"
                    className="assignments-img me-1"
                  />
                  <h6 onClick={handleClick}>7/30</h6>
                </div>
              </div>
            </div>}
          </div>
        

       
       
      </div>
    </div>
  );
}

export default S_dashboard;
