import React, {useEffect} from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "../styles/Student.css"
import { useNavigate } from "react-router-dom";

const Navb = ({result}) => {

  const uploadedImage = React.useRef(null);

  useEffect(() => {
    if(result) {
      uploadedImage.current.src=result
    } else {
      uploadedImage.current.src=require('../images/ProfilePlaceholder.png')
    }
  }, [result])

  const navigate = useNavigate();

  function handleClick() {
    navigate("/updateuser");
  }

  function handleClick1() {
    navigate("/dashboard");
  }

  return (
    <>

    <div className=' mt-4 mx-2 '>



    <Navbar className='bgwhite nav1 rounded-3' expand="lg">
      <Container fluid>
        {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          <Form className="d-flex position-relative">
            <Form.Control
              type="search"
              placeholder="Search here"
              className="ms-4 searchbar-custom rounded-3"
              aria-label="Search"
            />
            <button className='nav-searchbtn'>
            <img
                  className="nav-searchicon"
                  src={require("../images/searchbtn.png")}
                  alt="Image not found"
                />
                </button>
          </Form>
          
          </Nav>
          <Nav.Link onClick={handleClick} style={{ padding: '0px' }}>
          <img className='admin-pro-pic my-1 me-2'
          ref={uploadedImage}
      
        />
          </Nav.Link>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>



    <Navbar className='bgwhite nav2 rounded-3' expand="lg">
      <Container fluid>
        {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
          </Nav>
          <Nav.Link onClick={handleClick} className="border-bottom" href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic1.png')} alt="Image not found" />Dashboard</Nav.Link>
          <Nav.Link onClick={handleClick} href="#action1"><img className='me-2 mb-1' src={require('../images/sidepic2.png')} alt="Image not found" />Update User</Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
</div>
      
    </>
  )
}

export default Navb;
