import React from "react";
import Input from "../components/Input";
import "../styles/Forgetpass.css";
import { useState } from "react";
import { useNavigate} from "react-router-dom";
import httpsignup from '../js/http-lms_signup';

function ForgetPassword() {

  const [email, setEmail] = useState()

  const [loading, setLoading] = useState(false)

  const[msg,setMsg] = useState()


  const navigate = useNavigate()

  function handleClick(){
    navigate("/")
  }

  function emailChange(e){
    setEmail(e.target.value)
  }

  const submitEmail =async ()=>{

  localStorage.setItem("userEmail",email)

  setLoading(true)

    // const response=await axios.post("http://34.199.176.17:13051/userloginLMS/sendmailotp",{
   await httpsignup.post("/userloginLMS/sendmailotp",{
      userEmail:email
    }).then((result) => {
      console.log(result.data);
      if (result.data==='Record Not Found With This EmailId') {
        setMsg("Please enter email id registered with us");
      }
      else if(result.data==='Sent Email on your email address, please check your email') {
        setMsg(result.data);
        navigate("/verifyotp");
      }
    })

    // console.log(response, "Resphobiogyiu")

    setLoading(false)
    
  }

  return (
    <>

<div className='container d-flex justify-content-center align-items-center login-back-container'>

<div className=' bgwhite m-5 rounded-1 d-flex shadow-lg'>

    <div className='signin-image'>

    </div>

    {/* <img onClick={handleClick}
                  className="ps-3 pt-3 backarrow"
                  src={require("../images/leftarrow.png")}
                  alt="Image not found"
                /> */}

    <div className='px-5 d-flex flex-column login-details-container align-items-center'>


    <div className="logo">
    <img src={require("../images/lock.png")} alt="Image not found" />
  </div>

  <p className="forgetpass-heading">Forget Password</p>

  <p style={{
              color:"#A8A8A8",
              fontSize: "12px",
              marginRight: "15px",
              marginTop: "10px",
              marginBottom: '31px'
            }}
            >Please enter your email address to receive a OTP</p>

  <div>
    <p className="e-heading">E-mail ID</p>
    <Input type="email" name="email" change={emailChange} placeholder="@gmail.com" />
    {msg && <div className='errMsg mb-4'>{msg}</div>}
  </div>

  {/* <Button path="/verifyotp" onClick={submitEmail} class='forgetpass-btn' data={email} button="Get OTP" /> */}

  <button onClick={submitEmail} className='forgetpass-btn'>get otp</button>

  

  <p style={{color: "#010101",
      opacity: 0.52,fontSize: "10px"}}>@2022 all rights reserved</p>


        </div>


</div>

</div>

</>

  );
}

export default ForgetPassword;
