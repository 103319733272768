import React from "react";
import Button from "../components/Button";
import Button2 from "../components/Button2";
import { useLocation } from "react-router-dom";
import "../styles/Verifyotp.css";
import axios from "axios";
import { useState } from "react";
import { useNavigate} from "react-router-dom";
import { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_signup';

const VerifyOtp = () => {

const location = useLocation()

const[msg,setMsg] = useState()

const[otpMsg, setOtpMsg] = useState()

const [loading, setLoading] = useState(false)

const navigate = useNavigate()

const [otp, setOTP] = useState({
  first:'',
  second:'',
  third:'',
  fourth:'',
  fifth:'',
  sixth:'',
})


const submitOTP = async ()=>{

  // const response=await axios.put("http://localhost:12000/userlogin/verifyotp",{
  await httpsignup.put("/userloginLMS/verifyotp",{
    userEmail:localStorage.getItem("userEmail"),
    userOtp:Object.values(otp).join('')
  }
  ).then((result) => {
    console.log(result.data);
    if (result.data==='*Invalid OTP*. Please enter Valid OTP or click on Re-send OTP') {
      setMsg("Invalid OTP entered. Try again or click on Resend OTP");
    }
    else if(result.data==='Otp is verified') {
      setMsg(result.data);
      navigate("/updatepass");
    }
  })
  
  // console.log(response)
}





const resendOTP =async ()=>{

  setMsg()
  setOtpMsg(1)

  setLoading(true)

    // const response=await axios.post("http://34.199.176.17:13051/userloginLMS/sendmailotp",{
    await httpsignup.post("/userloginLMS/sendmailotp",{
      userEmail:localStorage.getItem("userEmail")
    }).then((res) => {
      console.log("resend OTP : ", res.data)
    })

    setLoading(false)
    
  }

  return (
    <>
    
<div className='container d-flex justify-content-center align-items-center login-back-container'>

<div className=' bgwhite m-5 rounded-1 d-flex shadow-lg'>

    <div className='signin-image'>

    </div>

    <div className='px-5 d-flex flex-column login-details-container align-items-center'>

    <div className="logo">
    <img src={require("../images/lock.png")} alt="Image not found" />
  </div>

  <p className="forgetpass-heading">Forget Password</p>

  <p style={{
              color:"#A8A8A8",
              fontSize: "12px",
              marginLeft: "20px",
              marginTop: "10px",
              marginBottom: '31px'
            }}
            >Please enter the 6 didgit code sent to {localStorage.getItem("userEmail")}</p>

          <div className="me-3 mb-3">
              <p className="e-heading mb-2">Enter OTP</p>
              <input className="verify-box me-2" onChange={(v)=>setOTP({...otp,
              first:v.target.value})} type="text" name="num1" maxLength={1}  />

              <input className="verify-box me-2" onChange={(v)=>setOTP({...otp,
              second:v.target.value})} type="text" name="num2" maxLength={1}  />

              <input className="verify-box me-2" onChange={(v)=>setOTP({...otp,
              third:v.target.value})} type="text" name="num3" maxLength={1}  />
              
              <input className="verify-box me-2" onChange={(v)=>setOTP({...otp,
              fourth:v.target.value})} type="text" name="num4" maxLength={1}  />

              <input className="verify-box me-2" onChange={(v)=>setOTP({...otp,
              fifth:v.target.value})} type="text" name="num5" maxLength={1}  />

              <input className="verify-box" onChange={(v)=>setOTP({...otp,
              sixth:v.target.value})} type="text" name="num6" maxLength={1}  />
            </div>
            {msg && <div className='errMsg mb-4'>{msg}</div>}
            {/* <Button2 button={"Resend OTP"} /> */}

            <button className="btn2" onClick={resendOTP}>Resend OTP</button>

            {otpMsg === 1 && (
                loading ? <Spinner animation="border" variant="warning" />  : <div className="successMsg">Sent OTP on your email address.</div>
            )}

            {/* <Button path={"/updatepass"} class="verify-otp-btn" data={location.state.data} button="Verify Otp" /> */}

            <button onClick={submitOTP} className="verify-otp-btn">Verify Otp</button>


  <p style={{color: "#010101",
      opacity: 0.52,fontSize: "10px"}}>@2022 all rights reserved</p>


        </div>


</div>

</div>

    </>
  );
};

export default VerifyOtp;
