import React from "react";
import Sidebar from "../components/Sidebar";
// import "../styles/AdminProfile.css";
import "../styles/App.css";
import "../styles/Dashboard.css";
import Navb from "../components/Navb";
import "../fonts/Roboto/Roboto-Black.ttf";
import Accordion from "react-bootstrap/Accordion";
import thum from "../images/vid2.png"
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import httpvideos from "../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';
import TopicVideo from './coursedetails/TopicVideo'
import TopicDetails from "./coursedetails/TopicDetails";
import CourseTopicsList from "./coursedetails/CourseTopicsList"
// event bus
import eventBus from "../js/Event-bus";
const Videos = () => {
  const params = useParams()
  const [courseData, setCourseData] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedTopicData, setSelectedTopicData] = useState({})
  const navigate = useNavigate();

  function navToPrograms() {
    navigate("/dashboard");
  }

  useEffect(() => {
    programDetails()
  }, [])
            
  function programDetails() {
    console.log("router parameters : ", params)
    setLoading(true)
    httpvideos.get('lmsVideoRecordingManager/api/v1/videoRecordingLMS?roleId='+sessionStorage.getItem("roleid")+'&loggedUserID='+sessionStorage.getItem("userId")+'&lmsProgramID='+params.id)
    .then((response) => {
      console.log("program details : ", response.data)
      setCourseData(response.data)
      setLoading(false)
    })
  }
  function selectTopicToView(e, topic, i){
    console.log("selected topic to view : ", topic , i)
    setSelectedTopicData(topic)
    eventBus.dispatch("send-selected-topic-info", { message: topic });
  }
  
  return (
    <>
      <Sidebar />
      {/* Main Container */}
      <div className="dashboard-container mx-4 right-container">
        <Navb />
        {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-5 mt-5"> <Spinner animation="border" variant="secondary" /></div> :
        <div className="scroll-container">
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-lg-12 d-flex">
              <div className="d-flex">
                <img onClick={navToPrograms} width="15px" className="me-2 pointer img-fluid" src={require("../images/leftarrow.png")} alt="back"/>
                <span className='pointer font18 bold ml-2' onClick={navToPrograms}>{courseData.lmsProgramName}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid scroll-container">
          <div className="row mt-3 h-100 justify-content-between">
            <div className="col-lg-5 h-100 scroll-section pb-4 radius10">
              <CourseTopicsList courseData={courseData}/>
            </div>

            <div className="col-lg-7 d-flex flex-column align-items-start pb-4 scroll-section h-100 radius10">
              <div className="bgwhite shadow col-12 fit-content-h radius10">
                <div className="videosection">
                  <TopicVideo/>
                </div>
                <div className="topicdetails p-3">
                  <TopicDetails/>
                </div>
                <div className="d-flex justify-content-between p-4 w-100">
                    <div className="d-flex">
                      <img src={require("../images/Previous.png")} alt="Image not found" className="me-1"/>
                      <h6 className="mb-0 d-flex align-items-center">Previous</h6>
                    </div>
                    <div className="d-flex">
                      <h6 className="mb-0 d-flex align-items-center">Next</h6>
                      <img src={require("../images/Next.png")} alt="Image not found" className="ms-1"/>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        }
      </div>
    </>
  );
};

export default Videos;
