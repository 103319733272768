import React from 'react'

const Submited = () => {



  return (
    <div>
       <table className="table whitecolor">
              <thead>
                <tr>
                  <th scope="col">Assignment Name</th>
                  <th scope="col">Program</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Submited Date</th>
                  <th scope="col">Submited Document</th>
                  <th scope="col">Feedback Document</th>
                  <th scope="col">Score</th>
                  <th scope="col">Feedback</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Keywords</td>
                  <td>Java</td>
                  <td>21/09/2022 5:00 pm</td>
                  <td>05/10/2022 5:00 pm</td>
                  <td>abc.pdf</td>
                  <td>abc.pdf</td>
                  <td>100</td>
                  <td>View</td>

                </tr>
                <tr>
                <td scope="row">Keywords</td>
                  <td>Python</td>
                  <td>21/09/2022 5:00 pm</td>
                  <td>05/10/2022 5:00 pm</td>
                  <td>abc.pdf</td>
                  <td>abc.pdf</td>
                  <td>100</td>
                  <td>View</td>
                </tr>
                <tr>
                <td scope="row">Keywords</td>
                  <td>c++</td>
                  <td>21/09/2022 5:00 pm</td>
                  <td>05/10/2022 5:00 pm</td>
                  <td>abc.pdf</td>
                  <td>abc.pdf</td>
                  <td>100</td>
                  <td>View</td>
                </tr>
              </tbody>
            </table>
    </div>
  )
}

export default Submited
