import React from "react";
import { useState, useEffect } from "react";
import eventBus from "../../js/Event-bus";
import httpvideos from "../../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';

const TopicDetails = () => {
    const [selectedTopicData, setSelectedTopicData] = useState({})
    const [topicFullData, setTopicFullData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        eventBus.on("send-selected-topic-info", (data) =>{
            console.log("inside course details section : ", data.message)
            setSelectedTopicData(data.message)
            if(data.message && data.message.lmsTopicMasterId){
                setLoading(true)
                console.log("calling serice to get topic data information")
                getTopicAssignmentData(data.message.lmsTopicMasterId)
            }
        });
    })
    function getTopicAssignmentData(topicId) {
        httpvideos.get('lmsAssignmentsAndMaterial/getAssignmentsAndMaterialFileLinks?RoleId=2&UserId='+sessionStorage.getItem('userEmail')+'&topicMasterId='+topicId)
        .then((response) => {
            console.log("topic data information : ", response.data)
            setTopicFullData(response.data)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }
    return (
        
        <div className="">
            {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-3"> <Spinner animation="border" variant="secondary" /></div> :
            <div>
                {(topicFullData.length > 0 &&topicFullData[0].lmsTopicMaster) ? <h5 className="my-4">{topicFullData[0].lmsTopicMaster.topicName}</h5> : <h5>Topic Name</h5>}
            <h6>Description</h6>
            {(topicFullData.length>0 &&topicFullData[0].lmsTopicMaster && topicFullData[0].lmsTopicMaster.s3PresignedTopicMaterialLink) ? <a href={topicFullData[0].lmsTopicMaster.s3PresignedTopicMaterialLink} target="_blank">Topic description</a> : <p className="font16 black60 bold">No Description found</p>}
            
            <div className="row my-4">
                <div className="col-lg-6 d-flex flex-column align-items-start">
                    <h6>Assignment Details</h6>
                    {topicFullData.length>0 && topicFullData[0].assignmentMaster && topicFullData[0].assignmentMaster.length>0 ? 
                        <div className="d-flex flex-column">
                            {topicFullData[0].assignmentMaster.map((assignment,i)=>{
                                return(
                                <a href={assignment.s3PresignedAssignmentLink} target="_blank" key={'assignment'+i}>{assignment.assignmentName}</a>
                                )
                            })}
                        </div>
                        : <p className="font16 black60 bold">No Assignments found</p>
                    }
                </div>

                {/* <div className="col-lg d-flex flex-column p-3 borderoutline mx-lg-5 mt-4 ms-2 rounded-top">
                    <h6>Deadline</h6>
                    <h5>15 Oct 22</h5>
                    <h5>12:00 PM (IST)</h5>
                </div> */}
            </div>
            
                {/* <h6>Exhibit File</h6>
                  <h6>Premative Assignmentm 1.pdf</h6> */}

                  <div className="d-flex flex-column align-items-center mx-auto border-file-upload mt-4">
                    <img
                      src={require("../../images/upload.png")}
                      alt="Image not found"
                      className=""
                    />
                    <h6 className="my-3">Exhibit File</h6>
                    <button className="profile-buttons">Upload</button>
                </div>
                </div>
            }
        </div>
    )
}
export default TopicDetails;