import React from 'react'
import Input from "../components/Input";
import Button from "../components/Button";
import Button2 from "../components/Button2";
import { useState } from "react";
import { useNavigate} from "react-router-dom";
import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_signup';
import { getRoles } from '@testing-library/react';
import "../styles/Signin.css"

const SignInBS = () => {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)


  const [pass, setPass] = useState('');

  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState();
  
  const [passError, setPassError] = useState();

  const [disable, setDisbale] = useState(true);


  useEffect(() => {
    if(pass.length>0 && email.length>0) {
      setDisbale(false);
    }
    else {
      setDisbale(true);
    }
  }, [pass, email])



  function passChange(e){
    setPass(e.target.value)
  }

  function emailChange(e){
    setEmail(e.target.value)
  }



  // useEffect(()=>{
  // getRoles()
  // }, [])


  // const getRoles = async ()=>{

  //   await axios.get("http://localhost:13051/userloginLMS/getUserRoles").then((result)=>{
  //     console.log(result.data)
  //     setRole(result.data)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }


  
  const SignInAuth =async ()=>{

    setLoading(true)
  
    // const response=await axios.post("http://34.199.176.17:13051/userloginLMS/validate",{
    await httpsignup.post("/userloginLMS/validate",{
      emailId:email,
      passWord:pass,
      roleId:2
    }).then((result) => {
      console.log(result.data);
      if (result.data[0].apiStatusMessage==='Account doesnt exist') {
        setEmailError("Please enter email id registered with us");
        setPassError();
      }
      else if(result.data[0].apiStatusMessage==='PASSWORD_INCORRECT') {
        setPassError("Please enter correct password");
        setEmailError();
      }
      else {

        const bearerToken = result.data[0].jwtToken;

        sessionStorage.setItem("JWT",bearerToken)
        sessionStorage.setItem("userEmail", result.data[0].userEmail)
        sessionStorage.setItem("userId", result.data[0].userId)
        sessionStorage.setItem("LOGGEDIN", true)

        navigate("/dashboard")
      }
      })

      setLoading(false)

  }




  return (
    <>
    
      <div className='container d-flex justify-content-center align-items-center login-back-container'>

        <div className=' bgwhite m-5 rounded-1 d-flex shadow-lg'>

            <div className='signin-image'>

            </div>

            <div className='px-5 d-flex flex-column login-details-container align-items-center'>

            <div className="logo pt-1">
            <img src={require('../images/Logo.png')} alt="Image not found" />
          </div>

          <p className="signin-heading">Sign in to Carrertuner</p>

          <div>
            <p className="e-heading">E-mail</p>
            <Input change={emailChange} type="email" name="email" placeholder="@gmail.com" />
            {emailError && <div className='errMsg mb-4'>{emailError}</div>}
          </div>

          <div>
            <p className="p-heading">Password</p>
            <Input change={passChange} type="password" name="pass" placeholder="Password" />
            {passError && <div className='errMsg mb-4'>{passError}</div>}
          </div>

          

          <Button2 path={"/forgetpass"} button={"Forget Password?"} />

          {/* <Button path={"/dashboard"} class={"signin-btn py-2 mb-4"} button={"Sign In"} /> */}

          <button onClick={SignInAuth} disabled={disable} className='signin-btn py-2 mb-4'>Sign In</button>

          <p style={{color: "#010101",
              opacity: 0.52,fontSize: "10px"}}>@2022 all rights reserved</p>


                </div>


        </div>

      </div>
    </>
  )
}

export default SignInBS
