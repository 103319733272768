import React from 'react'
import "../styles/Sidebar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

const Sidebar = () => {

        const [img1, setImg1] = useState('grey')

        const location = useLocation();



        // useEffect(() => {
        //         if(location.pathname==='/dashboard') {
        //              setImg1('green');
        //              document.getElementById('img2').addEventListener('mouseenter', mouseInImg2)
        //              document.getElementById('img2').addEventListener('mouseleave', mouseOutImg2)
        //              document.getElementById('img3').addEventListener('mouseenter', mouseInImg3)
        //              document.getElementById('img3').addEventListener('mouseleave', mouseOutImg3)
        //         }
        //          else if(location.pathname==='/assignments') {
        //              setImg2('green')
        //              document.getElementById('img1').addEventListener('mouseenter', mouseInImg1)
        //              document.getElementById('img1').addEventListener('mouseleave', mouseOutImg1)
        //              document.getElementById('img3').addEventListener('mouseenter', mouseInImg3)
        //              document.getElementById('img3').addEventListener('mouseleave', mouseOutImg3)
        //          } 
        //          else if(location.pathname==='/updateuser') {
        //              setImg3('green')
        //              document.getElementById('img1').addEventListener('mouseenter', mouseInImg1)
        //              document.getElementById('img1').addEventListener('mouseleave', mouseOutImg1)
        //              document.getElementById('img2').addEventListener('mouseenter', mouseInImg2)
        //              document.getElementById('img2').addEventListener('mouseleave', mouseOutImg2)
        //          }
        //      }, [location.pathname])





        function mouseInImg1() {
                setImg1('green')
        }

        function mouseOutImg1() {
                setImg1('grey')
        }

        const [img2, setImg2] = useState('grey')

        function mouseInImg2() {
                setImg2('green')
        }

        function mouseOutImg2() {
                setImg2('grey')
        }

        const [img3, setImg3] = useState('grey')

        function mouseInImg3() {
                setImg3('green')
        }

        function mouseOutImg3() {
                setImg3('grey')
        }

       
        const navigate = useNavigate();

        function handleClick() {
                navigate("/dashboard");
              }

              function handleClick2() {
                navigate("/assignments");
              } 

              function handleClick3() {
                navigate("/updateuser");
              } 

              function handleClick6() {
                navigate("/");
                sessionStorage.clear()
              } 


  return (
   <>
   <div className='container-fluid position-fixed sidebar'>

    <div className='row'>

        <div className='col-1 min-vh-100 bgwhite sidebar-height shadow'>

            <ul className=' list-unstyled'>

                    <li>
                            <a className=' d-flex justify-content-center mt-5 nav-link px-2'>
                                 <img src={require('../images/Logo.png')} alt="" />
                            </a>

                    </li>

                    <li id="img1" onClick={handleClick} onMouseEnter={mouseInImg1} onMouseLeave={mouseOutImg1}>
                            {img1 === 'grey' && <a className='d-flex flex-column align-items-center mt-4 pt-5 pb-2 nav-link px-2'>
                <img onClick={handleClick} src={require('../images/sidepic1.png')} alt="" />
                            </a>}
                            {img1 === 'green' && <a className='d-flex flex-column align-items-center mt-4 pt-5 pb-2 nav-link px-2'>
                <img onClick={handleClick} src={require('../images/sp1.png')} alt="" />
                            </a>}
                            <h6 className='sidebar-options pb-3'>Dashboard</h6>

                    </li>

                    <li id="img2" onClick={handleClick2}  onMouseEnter={mouseInImg2} onMouseLeave={mouseOutImg2}>
                    {img2 === 'grey' && <a className='d-flex flex-column align-items-center pb-2 nav-link px-2 pt-4'>
                            <img onClick={handleClick2} src={require('../images/sidepic5.png')} alt="" />
                            </a>}
                            {img2 === 'green' && <a className='d-flex flex-column align-items-center pb-2 nav-link px-2 pt-4'>
                            <img onClick={handleClick2} src={require('../images/sp5.png')} alt="" />
                            </a>}
                            <h6 className='sidebar-options pb-3'>Assignments</h6>

                    </li>

                    <li id="img2" onClick={handleClick3}  onMouseEnter={mouseInImg3} onMouseLeave={mouseOutImg3}>
                    {img3 === 'grey' && <a className='d-flex flex-column align-items-center pb-2 nav-link px-2 pt-4'>
                            <img onClick={handleClick3} src={require('../images/sidepic4.png')} alt="" />
                            </a>}
                            {img3 === 'green' && <a className='d-flex flex-column align-items-center pb-2 nav-link px-2 pt-4'>
                            <img onClick={handleClick3} src={require('../images/sp4.png')} alt="" />
                            </a>}
                            <h6 className='sidebar-options'>Update User</h6>

                    </li>

                    <li>
                    <a className='d-flex flex-column align-items-center pb-2 nav-link px-2 pt-5 mt-5'>
                            <img onClick={handleClick6} src={require('../images/logout.png')} alt="" />
                            </a>
                            <h6 className='sidebar-options'>Logout</h6>

                    </li>
                
            </ul>


        </div>


    </div>

   </div>
   </>
  )
}

export default Sidebar;
