import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import S_dashboard from "./pages/S_dashboard";
import Updateuser from "./pages/Updateuser";
import Signin from "./pages/Signin";
import Forgetpass from "./pages/Forgetpass";
import Verifyotp from "./pages/Verifyotp";
import Updatepass from "./pages/Updatepass";
import Videos from "./pages/Videos";
import Assigned from "./pages/Assignments/Assigned";
import Assignments from "./pages/Assignments/Assignments";
import { useEffect, useState } from 'react';


function App() {


  const[connection, SetConnection] = useState("")

  useEffect(() => {
    setInterval(() => {
      if (window.navigator.onLine) {
        SetConnection("Online")
      } else {
        SetConnection("Offline")
      }
    }, 1000)
  })

  return (
   <>

{connection === "Online" && (
   <BrowserRouter>
        <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/forgetpass" element={<Forgetpass />} />
        <Route path="/updatepass" element={<Updatepass />} />
        <Route path="/verifyotp" element={<Verifyotp />} />
          <Route path="/dashboard" element={<S_dashboard />} />
          <Route path="/updateuser" element={<Updateuser />} />
          <Route path="/course-details/:id" element={<Videos />} />
          <Route path="/assignments" element={<Assignments />} />
          <Route path="/assigned" element={<Assigned />} />
        </Routes>
      </BrowserRouter>)}

      {connection === "Offline" && (
        <div className="d-flex justify-content-center align-items-center wrapperOffline">
        <div className="msgBoxOffline py-4 px-5 d-flex">
          <h3>You are offline.</h3>
          <img
                    src={require("../src/images/offline2.png")}
                    alt=""
                    className="ms-3 offline-icon"
                  />
        </div>
        </div>)}
        
   </>
  );
}

export default App;
